import { RegisterRedux } from '../types'
import { AuthenServicesIF, ApiFetchStatus, AuthenServices } from '../../services'
import { RegisterActionTypes } from '../actionTypes'
import * as AppRedux from '../store.types'

import UserActions from './user.actions'
import { Api } from '../../instances'

const actions: RegisterRedux.RegisterAction = {
    setRegister: (registerStatus: string): AppRedux.AppReduxAction<RegisterRedux.SetRegisterActionData> => {
        return {
            type: RegisterActionTypes.setRegister,
            payload: {
                registerStatus
            }
        }
    },
    fetchRegister: register
}
function register(body: AuthenServicesIF.RegisterFormData, callback: (success: boolean) => void): RegisterRedux.RegisterActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setRegister(ApiFetchStatus.fetching))
        AuthenServices.register(body).then(res => {
            dispatch(actions.setRegister(ApiFetchStatus.success))
            dispatch(UserActions.setUser(res.profile))
            Api.setToken(res.token)
            callback(true)
        }).catch(err => {
            dispatch(actions.setRegister(ApiFetchStatus.error))
            callback(false)
        })
    }
}
export default actions