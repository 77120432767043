
import * as firebase from "firebase/app";
import '@firebase/messaging'
import { UserServices, UserServicesIF } from "../../services";
//@ts-ignore
import { NotificationManager } from 'react-notifications';

class Notification {
    static instance: Notification;

    static default() {
        if (!Notification.instance) {
            Notification.instance = new Notification()
        }
        return Notification.instance;
    }
    browserSupport = firebase.messaging.isSupported();
    messaging!: firebase.messaging.Messaging
    hasConfigured: boolean = false
    canshowNotification = true
    constructor() {
        const firApp = firebase.initializeApp({
            // Project Settings => Add Firebase to your web app
            messagingSenderId: "359733414792"
        });
        if (this.browserSupport) {
            this.messaging = firApp.messaging();

            this.messaging.usePublicVapidKey(
                // Project Settings => Cloud Messaging => Web Push certificates
                "BCn_SPMOuI6kgx2wyOezbPSZjlblrdCZ2vdcJjwwSIlsu8wGeBDCLmbxhGDTnLS8eqNuwB7WSiF5JLrYeXNmIFg"
            );
        }
    }

    configureNotification = () => {
        if (!this.hasConfigured && this.browserSupport) {
            this.messaging.requestPermission()
                .then(() => this.updateToken())
                .catch((err: any) => {
                    console.log("Unable to get permission to notify.", err);
                });
            navigator.serviceWorker.addEventListener("message", this.onMesseage);
        }
    }

    updateToken = (setting: string | null = null) => {
        this.hasConfigured = true
        this.messaging.getToken().then(token => {
            const body: UserServicesIF.UpdateDeviceTokenForm = {
                app_version: "1.0",
                os_name: "PWA",
                os_version: "1.0",
                token: token || "",
                type: 0,
                setting
            }
            UserServices.updateDeviceToken(body)
        })
    }

    onMesseage = (message: MessageEvent) => {
        console.log("=======message:", message);

        try {
            if (this.canshowNotification) {
                const { twi_body, title } = message.data["firebase-messaging-msg-data"].data

                NotificationManager.success(twi_body || title);
            }

        } catch (error) {

        }

    }
}

export default Notification.default()