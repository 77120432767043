import React from 'react'
import Loadable, { OptionsWithoutRender } from 'react-loadable';

import { WagerListProps } from './wager-list.type';

//@ts-ignore
import Loader from 'react-loader-spinner'
import { COLOR } from '../../../../res';

const loadableOptions: OptionsWithoutRender<WagerListProps> = {
    loader: () => import('./wager-list.view').then((WagerListPage) => {
        return WagerListPage
    }),
    loading: () => (<div className="flexCenter" style={{ height: "90%" }}>
        <Loader
            type="Puff"
            color={COLOR.SUCCESS}
            height="100"
            width="100"
        />
    </div>)
};

export default Loadable(loadableOptions);