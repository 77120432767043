import CANCEL from './cancel.png'
import COIN from './coin.png'
import COIN_STACK from './coin-stack.png'
import PIGGY from './piggy-bank.png'
import PIGGY2 from './piggy-bank-2.png'
import WG from './wager-green.png'
import WB from './wager-blue.png'
import WO from './wager-orange.png'
import WW from './wager_white.png'

export const DASHBOARD_IMGS = {
    CANCEL,
    COIN,
    PIGGY,
    PIGGY2,
    COIN_STACK,
    WG,
    WB,
    WO,
    WW
}