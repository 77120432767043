import { PasswordRedux } from '../types'
import { AuthenServicesIF, ApiFetchStatus, AuthenServices } from '../../services'
import { PasswordActionTypes } from '../actionTypes'
import * as AppRedux from '../store.types'

const actions: PasswordRedux.PasswordAction = {
    setForgotPassword: (forgotPasswordStatus: string): AppRedux.AppReduxAction<PasswordRedux.SetPasswordActionData> => {
        return {
            type: PasswordActionTypes.setForgotPassword,
            payload: {
                forgotPasswordStatus
            }
        }
    },
    fetchForgotPassword: forgotPassword,

    setChangePasswordToken: (changePasswordTokenStatus: string): AppRedux.AppReduxAction<PasswordRedux.SetChangePasswordTokenActionData> => {
        return {
            type: PasswordActionTypes.setChangeForgotPasswordToken,
            payload: {
                changePasswordTokenStatus
            }
        }
    },

    fetchChangePasswordToken: changePassToken
}
function forgotPassword(body: AuthenServicesIF.ForgorPasswordForm, callback: (success: boolean) => void): PasswordRedux.PasswordActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setForgotPassword(ApiFetchStatus.fetching))
        AuthenServices.forgotPassword(body).then(res => {
            dispatch(actions.setForgotPassword(ApiFetchStatus.success))
            callback(true)
        }).catch(err => {
            dispatch(actions.setForgotPassword(ApiFetchStatus.error))
            callback(false)
        })
    }
}

function changePassToken(body: AuthenServicesIF.ChangePasswordTokenFormData, callback: (success: boolean) => void): PasswordRedux.PasswordActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setForgotPassword(ApiFetchStatus.fetching))
        AuthenServices.changePasswordWithToken(body).then(res => {
            dispatch(actions.setForgotPassword(ApiFetchStatus.success))
            callback(true)
        }).catch(err => {
            dispatch(actions.setForgotPassword(ApiFetchStatus.error))
            callback(false)
        })
    }
}
export default actions