import React from 'react'
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from './sideMenu.redux';
import Sidebar from "react-sidebar";

import SideMenuHandle from './sideMenu.handle';
import { SideBarStyle } from './sideMenu.style';
import { BoxInfo } from '../../../components';

import { COLOR } from '../../../res';
import { withRouter } from 'react-router-dom';

class SideMenu extends SideMenuHandle {
    renderSideBarContent = () => {
        const { profile } = this.props
        const { items } = this.state
        if (profile) {
            return (
                <div style={{ backgroundColor: COLOR.BL80, width: "100%", height: "100%" }}>
                    <div style={SideBarStyle.bgProfile}>
                        <div style={{ backgroundColor: "rgba(51,51,51, 0.8)", width: "100%", height: "100%" }} className="flexCenter">
                            <BoxInfo
                                onClick={this.goToUpdate}
                                titleStyle={{ color: COLOR.WHITE, fontWeight: 500 }}
                                img={profile.avatar}
                                title={profile.name}
                                extInfo={
                                    <div>
                                        <div style={{ color: "#dddddd", textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }}>{profile.email}</div>
                                        <div style={{ color: COLOR.SUCCESS, marginTop: 5, fontWeight: 500 }} >{"Edit"}</div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div>
                        {items.map(x => {
                            return (
                                <div
                                    onClick={this.onClickItem(x)}
                                    key={x.name}
                                    style={SideBarStyle.item}
                                >
                                    <div
                                        style={{
                                            color: COLOR.WHITE,
                                            fontSize: "1.2em",
                                            fontWeight: 700
                                        }}
                                    >
                                        {x.name}
                                    </div>
                                    {x.badge > 0 && <div
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            height: 30,
                                            borderRadius: 15,
                                            backgroundColor: COLOR.DANGER,
                                            color: COLOR.WHITE,
                                            textAlign: "center"
                                        }}
                                        className="flexCenter"
                                    >{x.badge}</div>}
                                </div>
                            )
                        })}
                    </div>
                </div>)
        }
        return null

    }
    render() {
        const { sidebarOpen } = this.state
        return (
            <div>
                <Sidebar
                    rootClassName="layer"
                    sidebar={this.renderSideBarContent()}
                    styles={SideBarStyle.lib}
                    open={sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    sidebarId="side"
                >
                    {this.props.children}
                </Sidebar>
            </div>
        )
    }
}

const SidebarRoute = withRouter(SideMenu)

const SideMenuPage = connect(mapStateToProps, mapDispatchToProps)(SidebarRoute)

export default SideMenuPage

