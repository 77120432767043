import { UserProfile } from './../../entities';
import Chat, { Client } from 'twilio-chat'
import { UserServices } from '../../services';
import { AppNotification } from '../Notification';
import { store } from '../../store/store.all';

class TwilioChat {

    static instance: TwilioChat;

    static default() {
        if (!TwilioChat.instance) {
            TwilioChat.instance = new TwilioChat()
        }
        return TwilioChat.instance
    }
    identity!: string
    client!: Client
    ready!: boolean
    isSettuping!: boolean
    profile!: UserProfile

    handlers: Array<(status: boolean) => void> = []

    constructor() {
        this.ready = false
        this.isSettuping = false
    }

    addEventListener = (evt: "InitStatus" | "", handler: (status: boolean) => void) => {
        switch (evt) {
            case "InitStatus":
                return this.handlers.push(handler)
        }
    }
    removeEventListener = (evt: "InitStatus" | "", handler: (status: boolean) => void) => {
        const index = this.handlers.indexOf(handler);
        this.handlers.splice(index, 1);
    }
    init(id: number) {

        this.identity = id.toString()
        if (!this.ready && !this.isSettuping) {
            this.isSettuping = true
            this.getToken(id)
        }

    }

    getToken = (id: number) => {
        UserServices.getUserChatToken(Number(id)).then(data => {
            Chat.create(data.token).then(this.didSetupChatClient).catch(err => {
                this.isSettuping = false
            })
        })
    }
    didSetupChatClient = async (client: Client) => {
        this.client = client;
        if (store.getState().userReducer.notificationSetting.BANTER) {
            this.registerNotification()
        }else{
            this.deRegisterNotification()
        }

        this.updateUserProfile(this.profile)
        this.handlers.forEach(h => h(true))
        this.isSettuping = false
        this.ready = true
    }
    registerNotification = () => {

        try {

            console.warn("registerNotification")
            AppNotification.messaging.getToken().then(token => {
                this.client && this.client.setPushRegistrationId("fcm", token || "")
            })

        } catch (error) {

        }
    }
    deRegisterNotification = () => {

        this.client && this.client.unsetPushRegistrationId("fcm").then(OK => {
            console.warn("deRegisterNotification", OK)
        }).catch(err => console.warn("deRegisterNotification", err))


    }
    updateUserProfile = (profile: UserProfile) => {
        this.profile = profile
        this.client && this.client.user.updateFriendlyName(profile.name)
    }

    createChannel = async (uID: string, friendlyName: string) => {
        try {
            const channel = await this.client.createChannel({
                uniqueName: uID,
                friendlyName,
                attributes: {}
            })

            if (channel.status !== "joined") {
                await channel.join();
            }
            return channel
        } catch (error) {
            return Promise.reject()
        }

    }

    checkToCreateChannel = async (uID: string, friendlyName: string) => {

        try {
            const channel = await this.client.getChannelBySid(uID)
            if (channel.status !== "joined") {
                await channel.join();
            }
            return channel
        } catch (error) {

            return await this.createChannel(uID, friendlyName)
        }

    }

    shutdown = () => {
        this.ready = false
        this.client && this.client.shutdown()
    }
}

export default TwilioChat.default()