import React, { ReactElement, CSSProperties } from 'react'

import cln from 'classnames'
import './boxInfo.stylesheet.sass'

import { Avatar } from '../avatar'

export type BoxInfoProps = {
    img: string,
    title: string,
    right?: ReactElement,
    infoClassName?: string,
    titleStyle?: CSSProperties,
    extInfo?: ReactElement
    onClick?: () => void,
    style?: CSSProperties,
    avaSize?: number
}

export function BoxInfo(props: BoxInfoProps) {
    const { img, title, right, infoClassName, extInfo, onClick, titleStyle, style, avaSize } = props

    return (
        <div
            style={style}
            onClick={onClick}
            className={cln("box_info_container")}
        >
            <Avatar size={avaSize} source={img} />
            <div className={cln("info", infoClassName)} style={{ width: '70%' }}>
                <div
                    style={{ width: '100%', textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap", ...titleStyle }}
                    className={cln("info_title")}

                >
                    {title}
                </div>
                {extInfo}
            </div>
            {right}
        </div>

    )
}