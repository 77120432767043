export const COLOR = {
    SUCCESS: "#27B24A",
    DEFAULT: "#576B95",
    PRIMARY: "#4A90E2",
    WARNING: "#F5A623",
    GOLD: "#fffa00",
    ORANGE: "#F5A623",
    DANGER: "#dc3545",
    WHITE: "#ffffff",
    BLACK: "#000000",
    GRAY: "#DDDDDD",
    BORDER: "#EEEEEF",
    SHADOW: "rgba(51,51,51,0.3)",
    BL80: "#1B1A1F"
}