import React from 'react'
import './stripe-bank.styleCss.sass'
import classNames from 'classnames'
//@ts-ignore
import Loader from 'react-loader-spinner'

import { COLOR } from '../../res';
import { UserServices, UserServicesIF } from '../../services';
import CountryPicker from '../countryPicker/countryPicker.view';
import InputField from '../inputField/inputField.view';
import { UserProfile } from '../../entities';
import { UserBankInfoValidators } from '../../utils';

export const STRIPE_PK_KEY = 'pk_live_r5zIoDQ4WEmwrbtgBwP1ZOwZ'
export const STRIPE_SK_KEY = 'sk_live_HvtqdLMk0MaanQ1E4m20UDbO00CmrFQ48z'

type Props = {
    profile: UserProfile
    show: boolean,
    onCancel: () => void,
    onDidUpdateBank?: () => void
}
interface State extends UserServicesIF.UpdateBankFormData {
    isUpdateBank: boolean
}

export default class UpdateStripeBank extends React.PureComponent<Props, State>{

    constructor(props: Props) {
        super(props)
        const { bank } = props.profile
        console.log("-----bank", bank);

        this.state = {
            accountHolderName: bank ? bank.holder_name : "",
            accountNumber: bank ? bank.account_number : "",
            country: bank ? bank.country : "",
            isUpdateBank: false,
            routingNumber: bank && !!bank.routing_number ? bank.routing_number.replace(" ", "") : "",
        }
    }

    submit = () => {
        this.setState({ isUpdateBank: true })
        const { accountHolderName, accountNumber, routingNumber, country } = this.state;

        UserServices.updateBank({
            accountHolderName,
            accountNumber,
            routingNumber,
            country
        })
            .then(this.didUpdatebank)
            .catch(() => {
                this.setState({ isUpdateBank: false })
            })
    }

    didUpdatebank = () => {
        const { onDidUpdateBank } = this.props
        this.setState({ isUpdateBank: false })
        onDidUpdateBank && onDidUpdateBank()
    }
    render() {
        const { show, onCancel, profile } = this.props
        const { isUpdateBank } = this.state

        const { bank } = profile

        return (
            <div className={classNames("stripe_bank")} style={{ top: show ? 0 : "100vh" }}>
                <div className="header">
                    <div onClick={onCancel} style={{ color: COLOR.DEFAULT }}>Cancel</div>
                    <div style={{ fontSize: " 1.2em", fontWeight: 700 }}>Add a Bank</div>
                    <div onClick={this.submit}>
                        {isUpdateBank
                            ? <Loader
                                type="Puff"
                                color={COLOR.DEFAULT}
                                height="30"
                                width="30"
                            />
                            : "Done"}
                    </div>
                </div>
                <InputField
                    label="Holder Name"
                    placeholder="Enter Holder Name"
                    validator={UserBankInfoValidators.holderName()}
                    defaultValue={bank ? bank.holder_name : ""}
                    onChangeText={(accountHolderName) => this.setState({ accountHolderName })}
                />
                <InputField
                    label="BSB Number"
                    placeholder="Enter BSB Number"
                    validator={UserBankInfoValidators.bsb()}
                    inputProps={{ type: "numeric" }}
                    onChangeText={(routingNumber) => this.setState({ routingNumber })}
                    defaultValue={bank && !!bank.routing_number ? bank.routing_number.replace(" ", "") : ""}
                />
                <InputField
                    label="Account Number"
                    inputProps={{ type: "numeric" }}
                    defaultValue={bank ? bank.account_number : ""}
                    validator={UserBankInfoValidators.accountNumber()}
                    placeholder="Account Number"
                    onChangeText={(accountNumber) => this.setState({ accountNumber })}
                />
                <CountryPicker
                    defaultValue={bank ? bank.country : ""}
                    onChange={(country) => this.setState({ country })}
                />
            </div>

        )
    }
}