import LOGO from './logo.png'
import USER_AVT from './user-circle.png'
import COIN_STACK from './coin-stack.png'
import WAGER from './wager.png'
import A from './a.png'

export const APP_IMGS = {
    LOGO,
    USER_AVT,
    COIN_STACK,
    WAGER,
    A
}