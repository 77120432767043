import * as WagerServicesIF from './wager.types'
import { Api, AppApi, API_WAGER_URLS } from '../../instances'
import { format } from 'date-fns'
//@ts-ignore
import { NotificationManager } from 'react-notifications';

import { convertWagers, convertInvitedWager, convertDetailWager, convertWagerInvitedMembers } from './wagerservices.utils';

const DefaultRequestConfig: AppApi.RequestConfigProperties = {
    showMessage: false,
    showMessageError: true
}

const DefaultPostRequestConfig: AppApi.RequestConfigProperties = {
    showMessage: true,
    showMessageError: true
}

const WagerServices = {
    getWagerConfig: (config = DefaultRequestConfig): Promise<any> => {
        return Api.get<any>(API_WAGER_URLS.API_WAGER_CONFIG, config)
    },
    getWagerLists: (config = DefaultRequestConfig): Promise<WagerServicesIF.GetWagerListData> => {
        return Api.get<WagerServicesIF.GetWagerListResponse>(API_WAGER_URLS.API_LIST_WAGER, config).then((data) => {

            const wagers: Array<WagerServicesIF.WagerData> = convertWagers(data.wagers)

            return Promise.resolve({
                wagers
            })
        })
    },
    getHistoryWagerLists: (pageIndex: number, config = DefaultRequestConfig): Promise<WagerServicesIF.GetHistoryWagerListData> => {
        return Api.get<WagerServicesIF.GetHistoryWagerListRespone>(API_WAGER_URLS.API_LIST_WAGER_HISTORY(pageIndex), config).then((data) => {
            const wagers: Array<WagerServicesIF.WagerData> = convertWagers(data.wagers.data)
            return Promise.resolve({
                wagers,
                total: data.wagers.total,
                lastPage: data.wagers.last_page
            })
        })
    },
    getInvitedWagerLists: (config = DefaultRequestConfig): Promise<WagerServicesIF.GetWagerListData> => {
        return Api.get<WagerServicesIF.GetInvitedWagerListResponse>(API_WAGER_URLS.API_LIST_INVITED_WAGER, config).then((data) => {

            const wagers: Array<WagerServicesIF.WagerData> = convertInvitedWager(data.Invited)

            return Promise.resolve({
                wagers
            })
        })
    },
    getWagerDetail: (id: number, config = DefaultRequestConfig): Promise<WagerServicesIF.GetDetailWagerData> => {
        return Api.get<WagerServicesIF.GetDetailWagerResponse>(`${API_WAGER_URLS.API_DETAIL_WAGER}/${id}`, config).then((data) => {

            const wager: WagerServicesIF.WagerData = convertDetailWager(data.detail)
            const invitedMembers: Array<WagerServicesIF.MemberWager> = convertWagerInvitedMembers(data.invited_member)
            return Promise.resolve({
                wager,
                invitedMembers
            })
        })
    },
    searchFriend: (id: number, key: string, config = DefaultRequestConfig): Promise<WagerServicesIF.SearchFriendData> => {
        return Api.post<WagerServicesIF.SearchFriendResponse>(`${API_WAGER_URLS.API_SEARCH_USER}/${id}?search=${key}`, {}, config).then((data) => {

            return Promise.resolve({
                friends: data.result
            })
        })
    },
    seachListFriend: (id: number, key: string, config = DefaultRequestConfig): Promise<WagerServicesIF.SearchFriendData> => {
        return Api.get<WagerServicesIF.SearchFriendResponse>(
            API_WAGER_URLS.API_GET_LIST_FRIEND(id, key),
            config
        )
            .then((data) => {
                return Promise.resolve({
                    friends: data.result
                })
            })
    },
    inviteFriend: (id: number, memberID: number, config = DefaultPostRequestConfig): Promise<any> => {
        return Api.post<WagerServicesIF.SearchFriendResponse>(API_WAGER_URLS.API_INVITE_USER(memberID, id), {}, config)
    },
    inviteToApp: (id: number, email: string, config = DefaultPostRequestConfig): Promise<any> => {
        return Api.post<WagerServicesIF.SearchFriendResponse>(API_WAGER_URLS.API_INVITE_TO_APP(email, id), {}, config)
    },
    acceptWager: (id: number, result: string, config = DefaultPostRequestConfig): Promise<any> => {
        return Api.post<any>(API_WAGER_URLS.API_ACCEPT_INVITED_WAGER(id), { result }, config)
    },
    refuseWager: (id: number, config = DefaultPostRequestConfig): Promise<any> => {
        return Api.post<any>(API_WAGER_URLS.API_REFUSE_INVITED_WAGER(id), {}, config)
    },
    updateResult: (id: number, isWin: boolean, config = DefaultPostRequestConfig): Promise<any> => {
        return Api.post<any>(API_WAGER_URLS.API_UPDATE_RESULT_WAGER(id, isWin ? 1 : 2), {}, config)
    },

    fetchMemberInfos: (member_id: Array<number>, config = DefaultRequestConfig): Promise<WagerServicesIF.GetChatMemberInfoResponse> => {
        return Api.post<WagerServicesIF.GetChatMemberInfoResponse>(API_WAGER_URLS.API_GET_CHAT_MEMBER_INFO, { member_id }, config)
    },
    createWager: (
        data: WagerServicesIF.CreateWagerForm,
        config: AppApi.RequestConfigProperties = {
            showMessage: true,
            showMessageError: true
        }): Promise<WagerServicesIF.CreateWagerResponse> => {

        const { end_time, end_date } = data
        const date = format(end_date, "YYYY/MM/DD")
        const time = format(end_time, "HH:mm")

        const wagerEndTime = new Date(`${date} ${time}`).getTime()
        const canCreate = wagerEndTime - new Date().getTime() > 900000

        if (canCreate) {
            let body = Object.assign({
                name: data.name,
                result: data.result,
                bet: data.bet,
                end_time: wagerEndTime * 0.001
            })
            return Api.post<WagerServicesIF.CreateWagerResponse>(
                API_WAGER_URLS.API_CREATE_WAGER,
                body,
                config
            )
        } else {
            NotificationManager.warning("Mandatory, End time is more than current 15 minutes");
            return Promise.reject(400)
        }
    }
}
export default WagerServices