export const RouteNames = {

    Authenticate: {
        Login: "/",
    },
    Authenticated: {
        Home: "/dashboard",
        User: {
            Profile: "/user/profile",
            UpdateProfile: "/user/profile/update",
            UpdateCard: '/user/update-card',
            Coin: (page?: number) => typeof page !== "undefined" ? `/user/coin/${page}` : '/user/coin/:pageIndex',
            Notification: (page?: number) => typeof page !== "undefined" ? `/user/notification/${page}` : "/user/notification/:pageIndex",
            ChangePass: '/user/change-password',
            Friends: '/user/friends',
            NotificationSetting: '/user/notification-setting',
            FriendInvitation: '/user/friend-invitation',
            BuyDrawn: (type?: "BUY" | "WIDTH") => type ? `/user/buy-with-drawn/${type === "WIDTH" ? 1 : 0}` : '/user/buy-with-drawn/:type'
        },
        Wager: {
            CreateWager: "/wager/create-wager",
            WagerList: (tabIndex?: number) => typeof tabIndex !== "undefined" ? `/wager/list/${tabIndex}` : `/wager/list/:tabIndex`,
            WagerDetail: (id?: number) => id ? `/wager/detail/${id}` : "/wager/detail/:id",
            InivtedWager: (id?: number, accept: number = 0) => id ? `/wager/invited/${id}/${accept}` : "/wager/invited/:id/:accept",
            InviteFriend: (id?: number, word?: string) => id ? `/wager/${id}/invite/${word}` : "/wager/:id/invite/:word",
            WagerHistory: (page?: number) => typeof page !== "undefined" ? `/wager/history/${page}` : "/wager/history/:pageIndex",
            GroupChat: (id?: number) => id ? `/wager/chat/${id}` : `/wager/chat/:id`
        }
    }

}