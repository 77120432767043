import React from 'react'
import './popup.styleCss.sass'
import classNames from 'classnames'

import { PopupProps } from './popup.types'

class Popup extends React.PureComponent<PopupProps> {

    static defaultProps: PopupProps = {
        message: "success",
    }

    render() {
        const { onAccept, onCancel, title, message } = this.props
        return (
            <div
                className={classNames("popup_container card")}
            >
                <div className="popup_content">
                    <div className="title">{title}</div>
                    <div className="message"><p style={{ whiteSpace: 'pre-line' }}>{message}</p></div>
                    <div className="action">
                        <div onClick={onAccept} className="accept">OK</div>
                        <div onClick={onCancel} className="cancel">Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Popup