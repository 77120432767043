import BG_PROFILE from './bg_profile.png'
import BACK_ICON from './back_icon.png'
import BADGE from './badge.png'
import NOTIFICATION from './notification.png'
import WHITE_BACK from './white_back.png'
import MENU from './menu.png'
import CHAT from './chat.png'

export const LAYOUT_IMGS = {
    BG_PROFILE,
    BACK_ICON,
    BADGE,
    NOTIFICATION,
    WHITE_BACK,
    MENU,
    CHAT
}
