
import SideMenuSelector from './sideMenu.selector';
import { Api, TwilioChat } from '../../../instances'
import Manager from './sidemenu.manager'
import { BaseMenuItemProps } from './sideMenu.type';
import { RouteNames } from '../../../router'
class SideMenuHandle extends SideMenuSelector {

    componentDidMount() {
        super.componentDidMount && super.componentDidMount()
        Manager.setViewInstace(this)
    }

    onSetSidebarOpen = (open: boolean) => {
        this.props.fetchData()
        this.setState({ sidebarOpen: open });
    }

    goToUpdate = () => {
        this.props.history.push(RouteNames.Authenticated.User.UpdateProfile)
        this.onSetSidebarOpen(false)
    }

    goToRoute = (route: string) => {
        this.props.history.push(route)
    }

    onClickItem = (item: BaseMenuItemProps) => () => {
        this.onSetSidebarOpen(false)

        switch (item.name) {
            case "Logout":
                TwilioChat.shutdown()
                return Api.clearToken()
            default:
                this.goToRoute(item.event)
                break;
        }
    }
}

export default SideMenuHandle