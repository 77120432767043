import React from 'react'
import './dtpicker.styleCss.sass'
import classNames from 'classnames'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'

import { DateTimePickerProps, DateTimePickerState } from './dtpicker.types'
import { COLOR } from '../../res';

class DateTimePicker extends React.PureComponent<DateTimePickerProps, DateTimePickerState> {

    static defaultProps: DateTimePickerProps = {
        isTimePicker: false,
        label: "",
        defaultValue: new Date(),
        minDate: new Date()
    }
    regex: string;
    canClosePicker = false
    constructor(props: DateTimePickerProps) {
        super(props);
        this.regex = props.isTimePicker ? "hh:mm A" : "YYYY/MM/DD"
        this.state = {
            date: props.defaultValue!,
            open: false,
            preview: format(props.defaultValue ? props.defaultValue : new Date(), this.regex)
        }
    }

    getValue = () => this.state.date


    togglePicker = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        const { open } = this.state
        if (open) {
            this.canClosePicker && this.setState({ open: false })
        } else {
            this.setState({ open: true })
            this.canClosePicker = false
        }
    }

    handleChange = (date: Date) => {
        this.canClosePicker = true
        this.setState({
            date,
            open: false,
            preview: format(date, this.regex)
        });
        this.props.onChange && this.props.onChange()
    }
    render() {
        const { label, isTimePicker, minDate } = this.props
        const { date, open, preview } = this.state
        return (
            <div
                onClick={(e) => {
                    open || this.togglePicker(e)
                }}
                className={classNames("dt_picker_container")}
            >
                <div style={{ marginLeft: "30%" }}>
                    <DatePicker
                        startDate={date}
                        endDate={date}
                        onClickOutside={(e) => {
                            this.canClosePicker = true
                            this.togglePicker(e)
                        }}
                        minDate={minDate}
                        calendarClassName={classNames("calendar")}
                        selected={date}
                        open={open}
                        className="d-picker"
                        id="dt-picker"
                        customInput={<div />}
                        onSelect={this.handleChange}
                        onChange={this.handleChange}
                        showTimeSelect={isTimePicker}
                        showTimeSelectOnly={isTimePicker}
                        preventOpenOnFocus
                        showYearDropdown
                        scrollableYearDropdown
                        shouldCloseOnSelect
                        allowSameDay
                    />
                </div>
                <div className={classNames("input_field_container")}>
                    <div className={classNames("inputRow")}>
                        <div className={classNames('label')}>{label}</div>
                        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>{preview}</div>
                        <div style={{ width: 60, height: 45, }} className="flexCenter">
                            <i className="fa fa-calendar" style={{ color: COLOR.SUCCESS, fontSize: "1.5em" }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DateTimePicker