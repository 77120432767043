import { Reducer } from 'redux'
import { PasswordRedux } from '../types'
import { PasswordActionTypes } from '../actionTypes'

import { AppReduxAction } from '../store.types'

import { ApiFetchStatus } from '../../services'

const INITIAL_STATE: PasswordRedux.State = {
    forgotPasswordStatus: ApiFetchStatus.init,
    changePasswordTokenStatus: ApiFetchStatus.init,
}
const reducer: Reducer<PasswordRedux.State, AppReduxAction<any>> = (state = INITIAL_STATE, action: AppReduxAction<any>): PasswordRedux.State => {
    const { type, payload } = action
    switch (type) {
        case PasswordActionTypes.setForgotPassword:
            return Object.assign({}, payload)
        case PasswordActionTypes.setChangeForgotPasswordToken:
            return Object.assign({}, payload)
    }
    return state
}
export default reducer