import React from 'react'
import { CountryPickerProps, CountryPickerState } from './countryPicker.types';

class CountryPickerSelector extends React.PureComponent<CountryPickerProps, CountryPickerState> {
    static defaultProps: CountryPickerProps = {
        defaultValue: "",
        onChange: () => { },
    }

    constructor(props: CountryPickerProps) {
        super(props);
        this.state = {
            country: props.defaultValue || ""
        }
    }

    getValue = () => this.state.country

    setValue = (country: string) => this.setState({ country })

}

export default CountryPickerSelector