import React from 'react'
import './inputField.styleCss.sass'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import InputFieldHandle from './inputfield.handle'

class InputField extends InputFieldHandle {

    render() {
        const { 
            label, placeholder, inputProps, toggleShowPassord, 
            leftIcon, rightIcon, iconSize = 22,
            sufix,
        } = this.props
        const { value, errorText, showPassword } = this.state
        return (
            <div className={classNames("input_field_container")}>
                <div className={classNames("inputRow")}>
                    <div className={classNames('label')}>
                        {sufix}
                        {leftIcon && <span><img style={{ width: iconSize, height: iconSize, marginRight: 8 }} alt="icon" src={leftIcon} /></span>}
                        {label}
                    </div>
                    <input
                        className={classNames('input')}
                        placeholder={placeholder}
                        onChange={this.onChange}
                        value={value}
                        {...inputProps}
                        type={showPassword ? "email" : inputProps.type}
                    />
                    {toggleShowPassord
                        && <div
                            className={classNames("show_icon")}
                            onClick={this.toggleShowPassword}
                        >
                            <FontAwesomeIcon
                                color={"#556"}
                                style={{ fontSize: "1.7em" }}
                                icon={showPassword ? faEyeSlash : faEye}
                            />
                        </div>}
                    {rightIcon &&
                        <div
                            className={classNames("show_icon")}
                            onClick={this.toggleShowPassword}
                        >
                            <img style={{ width: 22, height: 22 }} src={rightIcon} alt="righticon" />
                        </div>
                    }
                </div>
                <p className={classNames("error")}>{errorText}</p>
            </div>
        )
    }
}

export default InputField