import { WagerServicesIF } from "./wager.all";
import { Wager, MemberWager, InvitedMemberWager } from '../../entities'
import { store } from '../../store'
import * as fns from 'date-fns'

function getLocalDate(date: Date) {
    return new Date(date.valueOf() - new Date().getTimezoneOffset() * 60000);
}

function getUntilFromWager(r: string) {
    let v = `${fns.parse(r)}`
    let endTime = new Date(v)
    let ret = new Date(getLocalDate(endTime))
    return ret
}

export function getMemberStatus(status: number, wager: Wager): WagerServicesIF.MemberWagerStatus {
    switch (status) {
        case 0:
            if (wager.status === "CLOSE") {
                return "FORGOT"
            }
            return "PLAYING"
        case 1:
            return "WIN"
        default:
            return "LOST"
    }

}

export function convertWagerMembers(members: Array<MemberWager>, wager: Wager) {
    return members.map((m): WagerServicesIF.MemberWager => {
        return {
            invitingMemberID: m.member_id,
            id: m.member_id,
            info: m.member,
            isHost: m.host === 1,
            result: m.result,
            status: getMemberStatus(m.status, wager)
        }
    })
}

export function convertWagerInvitedMembers(members: Array<InvitedMemberWager>) {
    return members.map((m): WagerServicesIF.MemberWager => {
        return {
            invitingMemberID: m.member_id,
            id: m.member_id,
            info: m.members,
            isHost: m.host === 1,
            result: m.result,
            status: m.status === "CANCEL" ? "CANCEL" : "INVITING"
        }
    })
}


export function converWagerStatus(wager: Wager) {
    switch (wager.status) {
        case "CLOSE":
            return "CLOSE"
        case "RUNNING":
            return "RUNNING"
        case "PLAYED":
            if (fns.parse(wager.end_time) < new Date()) return "UPDATE_RESULT"
            return "PLAYED"
    }
}

export function convertWagers(wagers: Array<Wager>): Array<WagerServicesIF.WagerData> {
    return wagers.map((raw): WagerServicesIF.WagerData => {
        const members = convertWagerMembers(raw.member_wagers, raw)
        const userID = store.getState().userReducer.profile.id
        const myInfo = members.find(x => x.id === userID)
        return {
            id: raw.id,
            endTime: getUntilFromWager(raw.end_time),
            hostID: raw.member_id,
            hostInfo: raw.member,
            name: raw.name,
            status: converWagerStatus(raw),
            members,
            total: Number(raw.total),
            myInfo
        }
    })

}

export function convertInvitedWager(invited: Array<WagerServicesIF.InvitedWagerResponse>): Array<WagerServicesIF.WagerData> {
    return invited.map((i): WagerServicesIF.WagerData => {
        const { wagers: raw } = i
        const members = convertWagerMembers(raw.member_wagers, raw)

        return {
            id: raw.id,
            endTime: getUntilFromWager(raw.end_time),
            hostID: raw.member_id,
            hostInfo: raw.member,
            name: raw.name,
            status: converWagerStatus(raw),
            members,
            total: Number(raw.total),
        }
    })

}

function getUserResult(wager: Wager) {
    const { myTransaction } = wager
    if (!myTransaction) return undefined
    return {
        coin: Math.abs(myTransaction.coins),
        isLost: Number(myTransaction.coins) < 0
    }
}

function getWargerResult(members: Array<MemberWager>) {
    let lost = 0, won = 0, forgot = 0;
    members.forEach(m => {
        m.status === 0 && forgot ++
        m.status === 1 && won ++
        m.status === 2 && lost ++
    })
    return {
        lost, won, forgot
    }
}

export function convertDetailWager(raw: Wager): WagerServicesIF.WagerData {
    const members = convertWagerMembers(raw.member_wagers, raw)
    const userID = store.getState().userReducer.profile.id
    const myInfo = members.find(x => x.id === userID)
    const hostResult = members.find(x => x.id === raw.member_id)!.result
    return {
        id: raw.id,
        endTime: getUntilFromWager(raw.end_time),
        hostID: raw.member_id,
        hostInfo: raw.member,
        name: raw.name,
        status: converWagerStatus(raw),
        hostResult,
        members,
        total: Number(raw.total),
        myInfo,
        myResult: getUserResult(raw),
        wagerResult: getWargerResult(raw.member_wagers)
    }

}
