import { LoginRedux } from '../types'
import { AuthenServicesIF, AuthenServices, ApiFetchStatus } from '../../services'
import { LoginActionTypes } from '../actionTypes'
import * as AppRedux from '../store.types'

import { Api } from '../../instances'

import UserActions from './user.actions'

const actions: LoginRedux.LoginAction = {
    setLogin: (loginStatus: string): AppRedux.AppReduxAction<LoginRedux.LoginActionData> => {
        return {
            type: LoginActionTypes.login,
            payload: {
                loginStatus
            }
        }
    },
    fetchLogin: login
}

function login(body: AuthenServicesIF.LoginBody, callback: (success: boolean) => void): LoginRedux.LoginActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setLogin(ApiFetchStatus.fetching))
        AuthenServices.login(body).then(res => {
            dispatch(actions.setLogin(ApiFetchStatus.success))
            dispatch(UserActions.setUser(res.profile))
            Api.setToken(res.token)
            callback(true)

        }).catch(err => {
            dispatch(actions.setLogin(ApiFetchStatus.error))
            callback(false)
        })
    }
}

export default actions