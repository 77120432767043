import React from 'react'
import './avtUpload.styleCss.sass'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import AvataUploadHandle from './avtUpload.handle'

import { COLOR } from '../../res';
import { APP_IMGS } from '../../assets'
class AvataUpload extends AvataUploadHandle {

    render() {
        // const {  } = this.props
        const { preview } = this.state
        return (
            <div className={classNames("avt_upload_container")}>
                <input
                    onChange={this.onChange}
                    type="file"
                    id="avtar-picker"
                    accept="image/x-png,image/gif,image/jpeg"
                // src={preview ? "" : APP_IMGS.USER_AVT}
                />

                <FontAwesomeIcon
                    className={classNames("pencil")}
                    icon={faPencilAlt}
                    color={COLOR.BL80}
                    size="2x"
                />

                {
                    // preview && 
                    < img
                        style={{ objectFit: "cover" }}
                        src={preview || APP_IMGS.USER_AVT}
                        alt={"avtar"}
                    />}
            </div>
        )
    }
}

export default AvataUpload