import React from 'react'
import Loadable, { OptionsWithoutRender } from 'react-loadable';

import { UpdateCardProps } from './update-card.type';

//@ts-ignore
import Loader from 'react-loader-spinner'
import { COLOR } from '../../../../res';

const loadableOptions: OptionsWithoutRender<UpdateCardProps> = {
    loader: () => import('./update-card.view').then((UpdateCardPage) => {
        return UpdateCardPage
    }),
    loading: () => (<div className="flexCenter" style={{ height: "90%" }}>
        <Loader
            type="Puff"
            color={COLOR.SUCCESS}
            height="100"
            width="100"
        />
    </div>)
};

export default Loadable(loadableOptions);