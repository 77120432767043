
import CountryPickerSelector from './countryPicker.selector'

class CountryPickerHandle extends CountryPickerSelector {

    selectCountry = (country: string) => {
        this.setState({ country });
        this.props.onChange && this.props.onChange(country)
    }
}

export default CountryPickerHandle