import React from 'react'
import './countryPicker.styleCss.sass'
import classNames from 'classnames'

import CountryPickerHandle from './countryPicker.handle'
//@ts-ignore
import { CountryDropdown } from 'react-country-region-selector';

class CountryPicker extends CountryPickerHandle {

    render() {
        const { country } = this.state
        return (
            <div className={classNames("country_picker_container")}>
                <div className={classNames("inputRow")}>
                    <div className={classNames('label')}>{"Country"}</div>
                    <div className={classNames('input')}>
                        <CountryDropdown
                            value={country}
                            valueType="short"
                            onChange={this.selectCountry}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default CountryPicker