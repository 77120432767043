export const API_ERROR_CODE = {
    REQUEST_ERROR: {
        CODE: 400,
        MESSAGE: "api_request_error"
    },
    INTENER_SERVER_ERROR: {
        CODE: 500,
        MESSAGE: "api_interner_server_error"
    },
    NO_RESPONSE: {
        CODE: 1000,
        MESSAGE: "api_no_response"
    },
    AUTHENTICATE: {
        CODE: 401,
        MESSAGE: "api_unauthenticate"
    },
    VAILDATE: {
        CODE: 422,
        MESSAGE: "api_request_validate"
    },
    VAILDATE_LOGIN: {
        CODE: 409,
        MESSAGE: "api_request_validate"
    },
    SERVER_DATA_ERROR: {
        CODE: 404,
        MESSAGE: "api_went_wroong"
    }
}