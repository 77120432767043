import { ChangeEvent } from 'react'
import AvataUploadSelector from './avtUpload.selector'

class AvataUploadHandle extends AvataUploadSelector {
    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files && files.length > 0) {
            const file = files[0];
            this.props.onChange &&  this.props.onChange()
            this.setState({
                file,
                preview: URL.createObjectURL(file)
            })
        }

    }
}

export default AvataUploadHandle