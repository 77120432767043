import * as AuthenServicesIF from './authen.types'
import { Api, AppApi, API_AUTH_URLS } from '../../instances'

const DefaultRequestConfig: AppApi.RequestConfigProperties = {
    showMessage: false,
    showMessageError: true
}

const AuthenServices = {
    login: (body: AuthenServicesIF.LoginBody, config = DefaultRequestConfig): Promise<AuthenServicesIF.LoginData> => {
        return Api.post<AuthenServicesIF.LoginResponse>(API_AUTH_URLS.API_LOGIN, body, config).then((response) => {
            return Promise.resolve({
                token: `${response.token_type} ${response.token}`,
                profile: { ...response.profile, coins: { num_of_coin: 0 } }
            })
        })
    },
    register: (body: AuthenServicesIF.RegisterFormData, config = DefaultRequestConfig): Promise<AuthenServicesIF.LoginData> => {

        const formData = new FormData();

        formData.append("first_name", body.firstName)
        formData.append("last_name", body.lastName)
        formData.append("name", `${body.firstName} ${body.lastName}`)
        formData.append("email", body.email)
        formData.append("password", body.password)
        formData.append("password_confirmation", body.password)

        body.avatar && formData.append("avatar", body.avatar)

        return Api.postForm<AuthenServicesIF.RegisterResponse>(API_AUTH_URLS.API_REGISTER, formData, config).then((response) => {
            return Promise.resolve({
                token: `${response.token_type} ${response.token}`,
                profile: { ...response.profile, coins: { num_of_coin: 0 } }
            })
        })
    },

    forgotPassword: (body: AuthenServicesIF.ForgorPasswordForm, config = DefaultRequestConfig): Promise<any> => {
        return Api.post<AuthenServicesIF.ForgorPasswordResponse>(API_AUTH_URLS.API_FORGOT_PASSWORD, body, config)
    },
    changePasswordWithToken: (body: AuthenServicesIF.ChangePasswordTokenFormData, config = { showMessage: true, showMessageError: true }): Promise<any> => {
        return Api.post<AuthenServicesIF.ChangePasswordTokenResponse>(API_AUTH_URLS.API_CHANGE_PASSWORD_WITH_TOKEN, body, config)
    },
}
export default AuthenServices