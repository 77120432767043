import React from 'react'
import { StatePickerProps, StatePickerState } from './statePicker.types';

class StatePickerSelector extends React.PureComponent<StatePickerProps, StatePickerState> {
    static defaultProps: StatePickerProps = {
        defaultValue: "",
        onChange: () => { },
        country: "US"
    }

    constructor(props: StatePickerProps) {
        super(props);
        this.state = {
            region: props.defaultValue || ""
        }
    }

    getValue = () => this.state.region

    setValue = (region: string) => this.setState({ region })

}

export default StatePickerSelector