import { LoginRedux } from '../types'
import { LoginActionTypes } from '../actionTypes'

import { Reducer } from 'redux'

import { AppReduxAction } from '../store.types'

const INITIAL_STATE: LoginRedux.State = {
    loginStatus: "init"
}
const reducer: Reducer<LoginRedux.State, AppReduxAction<any>> = (state = INITIAL_STATE, action: AppReduxAction<any>) => {
    const { type, payload } = action
    switch (type) {
        case LoginActionTypes.login:
            return Object.assign({}, payload)
    }
    return state
}
export default reducer