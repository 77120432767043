import SideMenuHandle from './sideMenu.handle'

class SidebarManager {
    static instance: SidebarManager;

    static default(): SidebarManager {
        if (!SidebarManager.instance) {
            SidebarManager.instance = new SidebarManager()
        };
        return SidebarManager.instance
    }

    view: SideMenuHandle | undefined

    setViewInstace = (view: SideMenuHandle) => {
        this.view = view
    }

    openDrawer = () => {
        if (this.view) {
            this.view.onSetSidebarOpen(true)
        }
    }
}

export default SidebarManager.default()