import { Reducer } from 'redux'
import { RegisterRedux } from '../types'
import { RegisterActionTypes } from '../actionTypes'

import { AppReduxAction } from '../store.types'

import { ApiFetchStatus } from '../../services'

const INITIAL_STATE: RegisterRedux.State = {
    registerStatus: ApiFetchStatus.fetching
}
const reducer: Reducer<RegisterRedux.State, AppReduxAction<any>> = (state = INITIAL_STATE, action: AppReduxAction<any>) => {
    const { type, payload } = action
    switch (type) {
        case RegisterActionTypes.setRegister:
            return Object.assign({}, payload)
    }
    return state
}
export default reducer