import React, { useState } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import { Api, AppApi } from '../instances'


function PrivateRoute({ component: Component, ...rest }: RouteProps) {
    const onAuthorizationChange = (status: AppApi.AuthorizationStatus) => {
        setAuthorization(status !== "UN_AUTH")
    }
    Api.addEventListener("AuthorizationStatusChange", onAuthorizationChange)
   
    const [authorization, setAuthorization] = useState(true)
    
    return (
        <Route
            {...rest}
            render={props => {
                return authorization ? (
                    //@ts-ignore
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location }
                            }}
                        />
                    )
            }}
        />
    );
}

export default PrivateRoute