import React from 'react'
import './button.styleCss.sass'
import classNames from 'classnames'

import { ButtonProps } from './button.types'

class Button extends React.PureComponent<ButtonProps> {

    static defaultProps: ButtonProps = {
        type: "success",
    }

    render() {
        const { label, containerStyle, labelStyles, disabled, containerProps, type, onclick } = this.props
        return (
            <div
                onClick={onclick}
                className={classNames("button_container", type, { "disabled": disabled })}
                style={containerStyle}
                {...containerProps}
            >
                <div style={labelStyles}>{label}</div>
            </div>
        )
    }
}

export default Button