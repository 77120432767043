
import StatePickerSelector from './statePicker.selector'

class StatePickerHandle extends StatePickerSelector {

    selectRegion = (region: string) => {
        this.setState({ region });
        this.props.onChange && this.props.onChange(region)
    }
}

export default StatePickerHandle