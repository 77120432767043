import React, { useState } from 'react';
import { Provider } from 'react-redux';
//@ts-ignore
import { NotificationContainer } from 'react-notifications';
//@ts-ignore
import { loadProgressBar } from 'axios-progress-bar'

import { AppRouter } from './router';

import store from './store/store.main';
import { Api } from './instances'

import './App.scss';
import 'react-notifications/lib/notifications.css';
import 'axios-progress-bar/dist/nprogress.css'
import { COLOR } from './res';
import { withRouter, RouteComponentProps } from 'react-router';
loadProgressBar(null, Api.api)

const UpdateNotification: React.FC<{ onReload: () => void } & RouteComponentProps> = (props) => {
    const { onReload } = props
    return (
        <div
            onClick={() => {
                const notification = document.getElementById('update_noti');
                if (notification) {
                    notification.className = 'update_hide';
                }
                window.location.reload()
                onReload()
            }}
            id="update_noti"
            style={{
                position: "fixed",
                bottom: 20,
                right: 0,
                color: COLOR.WHITE,
                background: COLOR.BL80,
                zIndex: 5,
                padding: 15,
                textAlign: "center"
            }} className="update_hide"
        >
            New update available, click here
        </div>
    )
}

const UpdateNotificationWithRouter = withRouter(UpdateNotification);

const App: React.FC<{ onReload: () => void }> = ({ onReload }) => {

    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }
    // @ts-ignore
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:

    const [showInstallMessage, setshowInstallMessage] = useState(isIos() && !isInStandaloneMode())
    setTimeout(() => {
        setshowInstallMessage(false)
    }, 3000);
    return (
        <div className="App" style={{ height: window.innerHeight }} >
            {showInstallMessage && <div
                style={{
                    position: "fixed",
                    bottom: 20,
                    right: 0,
                    color: COLOR.WHITE,
                    background: COLOR.BL80,
                    zIndex: 5,
                    padding: 15,
                    textAlign: "center"
                }}>
                Install this webapp on your iPhone: Tap share button and then Add to Homescreen
            </div>}
            <Provider store={store}>
                <AppRouter>
                    <UpdateNotificationWithRouter onReload={onReload} />
                </AppRouter>
            </Provider>
            {/* <div style={{ textOverflow: 'ellipsis', overflow: "hidden", }}> */}
            <NotificationContainer style={{ textOverflow: 'ellipsis', overflow: "hidden", }} />
            {/* </div> */}
        </div>
    );
}

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
            console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
        });
}

export default App;
