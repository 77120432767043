import axios, { AxiosInstance, AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'

import { store, UserActions } from '../../store'


import {
    ApiBaseResponse,
    RequestConfigProperties,
    RequestQueueItemProperties,
    AuthorizationStatusChangeHandler,
    AuthorizationStatus
} from './api.types'
import { API_ERROR_CODE } from './api.codes'
//@ts-ignore
import { NotificationManager } from 'react-notifications';

class AxiosClass {

    static instance: AxiosClass

    static default(): AxiosClass {
        if (!AxiosClass.instance) {
            AxiosClass.instance = new AxiosClass()
        }
        return AxiosClass.instance
    }
    api: AxiosInstance
    incrementRequestId: number = 0;
    requestQueue: Array<RequestQueueItemProperties> = [];
    token: string = "";

    authorizationStatus: AuthorizationStatus = "INIT";
    authorizationStatusChange: Array<AuthorizationStatusChangeHandler> = []

    errTime = new Date()

    constructor() {
        this.api = axios.create({
            // baseURL: "https://api.jointhewager.com/api",
            baseURL: process.env.REACT_APP_BASE_URL + "/api",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json",
            }
        })
        this.api.interceptors.response.use(this.interceptorResponses, this.handleErrors);
        this.revokeTokenFromLocalStoreage()
    }

    addEventListener = (evt: "AuthorizationStatusChange", handle: AuthorizationStatusChangeHandler) => {
        this.authorizationStatusChange.push(handle)
    }

    revokeTokenFromLocalStoreage() {
        const token = localStorage.getItem("TOKEN")
        if (token) {
            this.setToken(token)
            this.authorizationStatus = "AUTH"
            setTimeout(() => {
                //@ts-ignore
                store.dispatch(UserActions.getUserProfile())
            }, 500);

        } else {
            this.authorizationStatus = "UN_AUTH"
        }
        this.setToken(token || "")
    }
    clearToken() {
        localStorage.removeItem("TOKEN")
        this.authorizationStatus = "UN_AUTH"
        this.setToken("")
    }

    setToken = (token: string) => {
        this.token = token
        token && localStorage.setItem("TOKEN", token)
        this.api.defaults.headers['Authorization'] = token;
        this.authorizationStatusChange.forEach(h => h(token ? "AUTH" : "UN_AUTH"))
    }

    handleErrors = (error: AxiosError) => {
        let message = ""
        const { response, request, config } = error

        if (response) {
            console.log("interceptorerror response =======>>", response);
            return this.handleErrorOnResponse(response)
        } else if (request) {
            console.log("interceptorerror request =======>>", error.response);
            message = error.message
        } else {
            console.log("interceptorerror config =======>>", config);
            message = error.message
        }

        this.pushFlashMessage(message, config, false)
        return Promise.reject(error)
    }
    interceptorResponses = (response: AxiosResponse<ApiBaseResponse>): Promise<any> => {
        console.log("interceptorResponses response", response);
        const { data, config } = response;
        if (data.data) {
            this.pushFlashMessage(data.message, config, true);
            return Promise.resolve(data.data)
        } else {
            return Promise.reject({})
        }

    }

    getValidateMessage(data: { message: string, errors: { [key: string]: Array<string> } }): string {

        try {
            const { errors } = data
            let listError = Object.keys(errors).map(key => {
                return errors[key].pop()
            })

            let message = listError.join(";")
            return message ? message : data.message
        } catch (error) {
            // return I18n.t(API_ERROR_CODE.VAILDATE.MESSAGE)
            return ""
        }

    }

    handleErrorOnResponse = (response: AxiosResponse) => {
        const { data, status, config } = response;
        let message = ""
        let error;
        switch (status) {
            case API_ERROR_CODE.REQUEST_ERROR.CODE:
                message = data.data.message || "Some thing went wrong, try again later!"
                error = API_ERROR_CODE.REQUEST_ERROR;
                break;
            case API_ERROR_CODE.INTENER_SERVER_ERROR.CODE:
                message = data.message || "Interal server error, try again later"
                error = API_ERROR_CODE.INTENER_SERVER_ERROR
                this.pushFlashMessage(message, config, false)
                return Promise.reject({ error: { ...error, ...data.data }, message })
            // break;
            case API_ERROR_CODE.AUTHENTICATE.CODE:
                message = data.data.message || "Your session has been expried!"
                error = API_ERROR_CODE.AUTHENTICATE
                this.setToken("")
                this.authorizationStatus = "UN_AUTH"
                this.authorizationStatusChange.forEach(h => h(this.authorizationStatus))
                break;
            case API_ERROR_CODE.VAILDATE.CODE:
                message = this.getValidateMessage(data.data)
                error = API_ERROR_CODE.VAILDATE.CODE
                break;
            case API_ERROR_CODE.SERVER_DATA_ERROR.CODE:
                message = "Some thing went wrong, try again later!"
                error = API_ERROR_CODE.SERVER_DATA_ERROR.CODE
                break;
            default:
                message = "Some thing went wrong, try again later!"
                error = new Error("404");
                break;
        }
        this.pushFlashMessage(message, config, false)
        return Promise.reject({ error, message })

    }
    pushFlashMessage(message: string, config: AxiosRequestConfig, isSuccess: boolean = true) {
        const { _id } = config.headers;
        if (_id) {
            const requestItemIndex = this.requestQueue.findIndex(x => x.id === _id);
            const requestItem = this.requestQueue[requestItemIndex]
            if (requestItem && (isSuccess ? requestItem.config.showMessage : requestItem.config.showMessageError)) {
                if (isSuccess) {
                    NotificationManager.success(message);
                } else {
                    if (new Date().getTime() - this.errTime.getTime() > 500) {
                        this.errTime = new Date()
                        NotificationManager.error(message);
                    }
                }

            }
            this.requestQueue.splice(requestItemIndex, 1)
        }

    }

    pushReqestQueue = (config: RequestConfigProperties) => {
        this.incrementRequestId++;
        this.requestQueue.push({
            id: this.incrementRequestId,
            config
        })
    }

    get<T>(url: string, config: RequestConfigProperties = {}): Promise<T> {
        this.pushReqestQueue(config)
        return this.api.get(url, { headers: { _id: this.incrementRequestId } })
    }
    post<T>(url: string, body: Object, config: RequestConfigProperties = { showMessage: true }): Promise<T> {
        this.pushReqestQueue(config)
        return this.api.post(url, body, { headers: { _id: this.incrementRequestId } })
    }
    postForm<T>(url: string, body: FormData, config: RequestConfigProperties): Promise<T> {
        this.pushReqestQueue(config);
        return this.api.post(
            url,
            body,
            {
                headers: {
                    "_id": this.incrementRequestId,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )

    }
}

export default AxiosClass.default();
