import { CSSProperties } from 'react'
import { SidebarStyles as LibSideBarStyles } from "react-sidebar";

import { COLOR } from '../../../res'
import { LAYOUT_IMGS } from '../../../assets'

type SideBarStyleState = {
    lib: LibSideBarStyles,
    bgProfile: CSSProperties,
    item: CSSProperties
}

export const SideBarStyle: SideBarStyleState = {
    lib: {
        sidebar: {
            width: '80%',
            backgroundColor: COLOR.BL80,
        },
        content: {
            background: "#fff",
            overflowX: "hidden",
            border: `1px solid ${COLOR.BORDER}`
        },
    },
    bgProfile: {
        width: "100%",
        height: '22vh',
        backgroundImage: `url(${LAYOUT_IMGS.BG_PROFILE})`,
        display: "flex",
        alignItems: "center"
    },
    item: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 15
    }
}