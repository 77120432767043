export const API_AUTH_URLS = {
    API_REGISTER: '/auth/signup',
    API_LOGIN: '/auth/login',
    API_LOGOUT: '/auth/logout',
    API_FORGOT_PASSWORD: "/forgotPassword",
    API_CHANGE_PASSWORD_WITH_TOKEN: "/changePasswordWithToken",
}
export const API_USER_URLS = {
    API_GET_PROFILE: '/auth/user',
    API_MEMBER_GET_TWILIO_TOKEN: '/member/twilio_token',
    API_MEMBER_UPDATE_DEVICES: '/auth/updateToken',
    API_MEMBER_ADD_CART: '/auth/member/addCard',
    API_MEMBER_ADD_BANK: '/auth/member/addBank',
    API_MEMBER_UPDATE_PROFILE: '/member/update',
    API_MEMBER_GET_SYSTHESIS: '/member/synthesis',
    API_LIST_NOTIFICATION: (pageIndex: number) => `/member/listNotification?page=${pageIndex}`,
    API_READ_NOTIFICATION: '/notification/read',
    API_BUY_COIN: "/auth/member/buyCoin",
    API_WITH_DRAWN: "/auth/member/withDraw",
    API_LIST_TRANSACTION: (pageIndex: number) => `/wager/transactions?page=${pageIndex}`,
    API_CHANGE_PASSSWORD_WITH_AUTHENTICATED: "/auth/member/changePassword",
    API_GET_DB_CONFIG: "/dbConfig",
    API_SEARCH_MEMBER: "/member/searchMember",
    API_LIST_FRIEND: "/member/listFriend",
    API_LIST_PENDING_FRIEND: "/member/listPendingFriend",
    API_ADD_FRIEND: "/member/addFriend",
    API_ACCEPT_FRIEND: "/member/acceptFriend",
    API_REFUSE_FRIEND: "/member/refuseFriend",
    API_UN_FRIEND: "/member/unFriend",
    API_INVITE_FRIEND_TO_APP: "/member/searchInviteMember",
    API_VERSION: "/getVersion"
}
export const API_WAGER_URLS = {
    API_CREATE_WAGER: '/wager/create',
    API_LIST_WAGER: '/listwagers',
    API_LIST_WAGER_HISTORY: (id: number) => `/wager/history?page=${id}`,
    API_WAGER_CONFIG: '/getForgotNote',
    API_DETAIL_WAGER: '/wagers', // /wagers/{id},
    API_SEARCH_USER: "/invited/search",
    API_INVITE_USER: (memberID: number, wagerID: number) => `/invited/${memberID}/wager/${wagerID}`,
    API_INVITE_TO_APP: (key: string, wagerID: number) => `/search/${wagerID}?search=${key}`,
    API_LIST_INVITED_WAGER: "/invited/list/wager",
    API_REFUSE_INVITED_WAGER: (id: number) => `/member/cancel/${id}`,
    API_ACCEPT_INVITED_WAGER: (id: number) => `/member/accpect/${id}`,
    API_UPDATE_RESULT_WAGER: (id: number, result: number) => `/wager/update/result/${id}?result=${result}`,
    API_GET_CHAT_MEMBER_INFO: "/chat/members",
    API_GET_LIST_FRIEND: (id: number, key: string) => `invited/listFriend/${id}?search=${key}`
}