import { combineReducers } from 'redux'
import { loginReducer, userReducer, registerReducer, passwordReducer, wagerReducer } from './reducers'

import { AppReduxReducer } from './store.types'

const RootReducer: AppReduxReducer = {
    loginReducer,
    passwordReducer,
    registerReducer,
    userReducer,
    wagerReducer
}

export default combineReducers(RootReducer)

