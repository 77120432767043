import { UserRedux } from '../types'
import { UserProfile, UserNotification } from '../../entities'
import { UserActionTypes } from '../actionTypes'
import * as AppRedux from '../store.types'
import { UserServices, UserServicesIF, ApiFetchStatus } from '../../services'
import { TwilioChat } from '../../instances';

const actions: UserRedux.UserAction = {
    setUser: (profile: UserProfile): AppRedux.AppReduxAction<UserRedux.SetUserActionData> => {
        return {
            type: UserActionTypes.setUser,
            payload: {
                profile
            }
        }
    },

    setNotificationSetting: (notificationSetting: UserServicesIF.NotificationSetting): AppRedux.AppReduxAction<UserRedux.SetNotificationSetting> => {
        if (!notificationSetting.BANTER) {
            TwilioChat.deRegisterNotification()
        } else {
            TwilioChat.registerNotification()
        }
        return {
            type: UserActionTypes.setUser,
            payload: { notificationSetting }
        }
    },

    setGetUserProfile: (getUserProfileStatus: string, profile?: UserProfile): AppRedux.AppReduxAction<UserRedux.SetGetUserProfile> => {
        return {
            type: UserActionTypes.setUser,
            payload: Object.assign({}, { getUserProfileStatus }, profile && { profile })
        }
    },

    getUserProfile: getProfile,
    setGetStatisticData: (getStatisticDataStatus: string, statisticData?: UserRedux.StatisticData): AppRedux.AppReduxAction<UserRedux.SetGetStatisticData> => {
        return {
            type: UserActionTypes.getGetTransaction,
            payload: Object.assign({ getStatisticDataStatus }, statisticData && { statisticData })
        }
    },

    getStatisticData,

    setGetTransaction: (
        getTransactionStatus: string,
        transactions?: Array<UserServicesIF.UserTransaction>,
        listTransactionLastPage?: number
    ): AppRedux.AppReduxAction<UserRedux.SetGetTransactionData> => {
        return {
            type: UserActionTypes.getGetTransaction,
            payload: Object.assign({ getTransactionStatus }, transactions && { transactions, listTransactionLastPage })
        }
    },

    getTransactions,

    setGetNotification: (
        getNotificationStatus: string,
        notificaions?: Array<UserNotification>,
        listNotificationLastPage?: number
    ): AppRedux.AppReduxAction<UserRedux.SetGetNotificationData> => {
        return {
            type: UserActionTypes.getGetNotificaion,
            payload: Object.assign({ getNotificationStatus }, notificaions && { notificaions, listNotificationLastPage })
        }
    },

    getNotification
}

function getProfile(callback?: (success: boolean) => void): UserRedux.UserActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetUserProfile(ApiFetchStatus.fetching))

        UserServices.getUserProfile().then(res => {
            dispatch(actions.setGetUserProfile(ApiFetchStatus.success, res.profile))
            callback && callback(true)
        }).catch(err => {
            dispatch(actions.setGetUserProfile(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}

function getStatisticData(callback?: (success: boolean) => void): UserRedux.UserActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetStatisticData(ApiFetchStatus.fetching))
        UserServices.fetchStatisticData().then(res => {
            console.log("----res", res);

            dispatch(actions.setGetStatisticData(ApiFetchStatus.success, res))
            callback && callback(true)

        }).catch(err => {
            dispatch(actions.setGetStatisticData(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}

function getTransactions(pageIndex: number, callback?: (success: boolean) => void): UserRedux.UserActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetTransaction(ApiFetchStatus.fetching))
        UserServices.getTransaction(pageIndex).then(res => {
            dispatch(actions.setGetTransaction(ApiFetchStatus.success, res.data, res.lastPage))
            callback && callback(true)

        }).catch(err => {
            dispatch(actions.setGetTransaction(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}

function getNotification(pageIndex: number, callback?: (success: boolean) => void): UserRedux.UserActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetNotification(ApiFetchStatus.fetching))
        UserServices.getNotifications(pageIndex).then(res => {
            dispatch(actions.setGetNotification(ApiFetchStatus.success, res.data, res.lastPage))
            callback && callback(true)

        }).catch(err => {
            dispatch(actions.setGetNotification(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}
export default actions