import { Reducer } from 'redux'
import { UserRedux } from '../types'
import { UserActionTypes } from '../actionTypes'

import { AppReduxAction } from '../store.types'

import { ApiFetchStatus } from '../../services'
import { TwilioChat } from '../../instances';

const INITIAL_STATE: UserRedux.State = {
    notificationSetting: {

    },
    getUserProfileStatus: ApiFetchStatus.init,
    getStatisticDataStatus: ApiFetchStatus.init,
    listTransactionLastPage: 0,
    getTransactionStatus: ApiFetchStatus.init,
    transactions: [],
    getNotificationStatus: ApiFetchStatus.init,
    listNotificationLastPage: 0,
    notificaions: [],
    statisticData: {
        won: 0,
        lose: 0,
        unReadNotification: 0,
        refused: 0,
        commission_percent: 0
    },
    profile: {
        active: 0,
        address: "",
        avatar: "",
        card_brand: "",
        card_last_four: "",
        country: "",
        created_at: "",
        deleted_at: "",
        email: "",
        id: Number(localStorage.getItem("USER_ID")),
        name: "",
        first_name: "",
        last_name: "",
        phone: "",
        stripe_connect_id: "",
        stripe_id: "",
        trial_ends_at: "",
        updated_at: "",
        city: "",
        state: "",
        postal_code: "",
        date_of_birth: new Date(),
        coins: {
            num_of_coin: 0
        }
    }
}
const reducer: Reducer<UserRedux.State, AppReduxAction<any>> = (state = INITIAL_STATE, action: AppReduxAction<any>) => {
    const { type, payload } = action
    let nextState: UserRedux.State
    switch (type) {
        case UserActionTypes.setUser:
            nextState = Object.assign({}, state, payload)
            break
        case UserActionTypes.setGetProfile:
            nextState = Object.assign({}, state, payload)
            break
        case UserActionTypes.setGetStatisticData:
            nextState = Object.assign({}, state, payload)
            break
        case UserActionTypes.getGetTransaction:
            nextState = Object.assign({}, state, payload)
            break
        case UserActionTypes.getGetNotificaion:
            nextState = Object.assign({}, state, payload)
            break
        case UserActionTypes.setNotificationSetting:
            nextState = Object.assign({}, state, payload)
            break
        default:
            return state
    }
    localStorage.setItem("USER_ID", nextState.profile.id + "")
    TwilioChat.updateUserProfile(state.profile)
    return nextState
}
export default reducer