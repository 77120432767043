import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { SideMenuDispatchProps, SideMenuReduxProps } from "./sideMenu.type";

import { AppReduxState, UserActions, WagerActions } from '../../../store'

export const mapStateToProps = (state: AppReduxState): SideMenuReduxProps => {
    const { profile, statisticData } = state.userReducer;
    const { wagers, totalWagerHistory } = state.wagerReducer;
    return {
        profile,
        notificationCount: statisticData.unReadNotification,
        wagerCount: wagers.length,
        historyWagerCount: totalWagerHistory
    }
}

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppReduxState, undefined, Action>): SideMenuDispatchProps => {
    return {
        fetchData: () => {
            dispatch(UserActions.getUserProfile())
            dispatch(UserActions.getStatisticData())
            dispatch(WagerActions.getWagerList())
            dispatch(WagerActions.getInvitedWagerList())
            dispatch(WagerActions.getHistoryWagerList())
        }
    }
}