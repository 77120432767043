import React from 'react'

import { BrowserRouter, Route } from 'react-router-dom';

import PrivateRoute from './private.route'

import LoginPage from '../containers/authenticate/login/login.loadable'
import RegisterPage from '../containers/authenticate/register/register.loadable'
import ForgotPassword from '../containers/authenticate/fogotPassword/forgotPassword.loadable'
import ChangePasswordToken from '../containers/authenticate/changePasswordWithToken/cPassToken.loadable'
import CreateWager from '../containers/authenticated/wagers/createWager/createWager.loadable'
import WagerDetail from '../containers/authenticated/wagers/wagerDetail/wagerDetail.loadable'
import InviteFriend from '../containers/authenticated/wagers/inviteFriend/inviteFriend.loadable'
import InviteWager from '../containers/authenticated/wagers/invited-wager/invited-wager.loadable'
import WagerList from '../containers/authenticated/wagers/wager-list/wager-list.loadable'
import WagerHistory from '../containers/authenticated/wagers/wager-history/wager-history.loadable'
import WagerChat from '../containers/authenticated/wagers/group-chat/group-chat.loadable'
import Profile from '../containers/authenticated/users/profile/profile.loadable'
import UpdateProfile from '../containers/authenticated/users/update-profile/update-profile.loadable'
import UpdateCard from '../containers/authenticated/users/update-card/update-card.loadable'
import Coin from '../containers/authenticated/users/coin/coin.loadable'
import BuyDrawn from '../containers/authenticated/users/buy-drawn/buy-drawn.loadable'
import Notificaion from '../containers/authenticated/users/notification/notification.loadable'
import ChangePass from '../containers/authenticated/users/change-password/change-password.loadable'
import NotificationSetting from '../containers/authenticated/users/notification-setting/notification-setting.loadable'
import Friends from '../containers/authenticated/users/friends/friends.loadable'
import FriendInvitation from '../containers/authenticated/users/friend-invitation/friend-invitation.loadable'

import { SideMenuPage } from '../containers/layouts/sideMenu'

import HomePage from '../containers/authenticated/pages/home/home.loadable'
import { RouteNames } from './router.name';


const AppRouter: React.FC = (props) => {
  return (
    <BrowserRouter>
      {props.children}
      <SideMenuPage>
        <Route
          path={RouteNames.Authenticate.Login}
          component={LoginPage}
          exact
        />
        <Route
          path="/register"
          component={RegisterPage}
          exact
        />
        <Route
          path="/forgot-password"
          component={ForgotPassword}
          exact
        />
        <Route
          path="/change-password-token/:email"
          component={ChangePasswordToken}
          exact
        />

        <PrivateRoute
          path={RouteNames.Authenticated.Home}
          component={HomePage}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.CreateWager}
          component={CreateWager}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.WagerDetail()}
          component={WagerDetail}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.InviteFriend()}
          component={InviteFriend}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.InivtedWager()}
          component={InviteWager}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.WagerList()}
          component={WagerList}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.WagerHistory()}
          component={WagerHistory}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.Wager.GroupChat()}
          component={WagerChat}
          exact
        />

        <PrivateRoute
          path={RouteNames.Authenticated.User.Profile}
          component={Profile}
          exact
        />

        <PrivateRoute
          path={RouteNames.Authenticated.User.UpdateProfile}
          component={UpdateProfile}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.UpdateCard}
          component={UpdateCard}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.Coin()}
          component={Coin}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.BuyDrawn()}
          component={BuyDrawn}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.Notification()}
          component={Notificaion}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.ChangePass}
          component={ChangePass}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.NotificationSetting}
          component={NotificationSetting}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.Friends}
          component={Friends}
          exact
        />
        <PrivateRoute
          path={RouteNames.Authenticated.User.FriendInvitation}
          component={FriendInvitation}
          exact
        />
        {/* <AssetVersionning /> */}
      </SideMenuPage>

    </BrowserRouter>
  );
}

export default AppRouter