import React from 'react'
// import styled from 'styled-components'
// import { COLOR } from '../../../res'
import './link.stylesheet.sass'
import { Link } from 'react-router-dom'

export const CenterLink = (props: { label: string, href: string }) => {
    const { label, href } = props
    return (
        <div className="link_wrapper">
            <Link
                to={href}
            >
                {label}
            </Link>
        </div>
    )
}

export const DescriptionLink = (props: { label: string, href: string, desciption: string }) => {
    const { label, href, desciption } = props
    return (
        <div className="description_link">
            <div>{desciption}</div>
            <Link
                to={href}
            >
                {label}
            </Link>
        </div>
    )
}
