import React from 'react'
import Loadable, { OptionsWithoutRender } from 'react-loadable';

import { GroupChatProps } from './group-chat.type';

//@ts-ignore
import Loader from 'react-loader-spinner'
import { COLOR } from '../../../../res';

const loadableOptions: OptionsWithoutRender<GroupChatProps> = {
    loader: () => import('./group-chat.view').then((GroupChatPage) => {
        return GroupChatPage
    }),
    loading: () => (<div className="flexCenter" style={{ height: "90%" }}>
        <Loader
            type="Puff"
            color={COLOR.SUCCESS}
            height="100"
            width="100"
        />
    </div>)
};

export default Loadable(loadableOptions);