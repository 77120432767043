import React from 'react'
import './statePicker.styleCss.sass'
import classNames from 'classnames'

import StatePickerHandle from './statePicker.handle'
//@ts-ignore
import { RegionDropdown } from 'react-country-region-selector';

class StatePicker extends StatePickerHandle {

    render() {
        const { country } = this.props
        const { region } = this.state
        return (
            <div className={classNames("state_picker_container")}>
                <div className={classNames("inputRow")}>
                    <div className={classNames('label')}>{"State"}</div>
                    <div className={classNames('input')}>
                        <RegionDropdown
                            country={country}
                            value={region}
                            valueType="short"
                            countryValueType="short"
                            onChange={this.selectRegion}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default StatePicker