import { ChangeEvent } from 'react'
import InputFieldSelector from './inputfield.selector'

class InputFieldHandle extends InputFieldSelector {
    toggleShowPassword = () => {
        this.setState(prev => {
            return {
                showPassword: !prev.showPassword
            }
        })
    }
    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { validator, onChangeText } = this.props
        const { value } = e.target
        const errorText = validator && validator(value)
        this.setState({
            value: value,
            errorText: errorText || ""
        }, () => onChangeText && onChangeText(value))

    }
}

export default InputFieldHandle