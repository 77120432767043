import React from 'react'
import './stripe-card.styleCss.sass'
import classNames from 'classnames'
//@ts-ignore
import Loader from 'react-loader-spinner'

import { CardElement, injectStripe, ReactStripeElements, Elements, StripeProvider } from 'react-stripe-elements';

import { COLOR } from '../../res';
import { UserServices } from '../../services';

export const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_KEY || '' // test
export const STRIPE_SK_KEY = process.env.REACT_APP_STRIPE_SECRET || '' //test

// export const STRIPE_PK_KEY = 'pk_live_r5zIoDQ4WEmwrbtgBwP1ZOwZ'
// export const STRIPE_SK_KEY = 'sk_live_HvtqdLMk0MaanQ1E4m20UDbO00CmrFQ48z'

type Props = {
    userName: string,
    show: boolean,
    onCancel: () => void,
    onDidUpdateCard?: () => void
}

class StripeCardInfo extends React.PureComponent<Props & ReactStripeElements.InjectedStripeProps>{

    state = { isUpdateCard: false }

    submit = () => {
        const { stripe, userName } = this.props
        if (stripe) {
            this.setState({ isUpdateCard: true })
            stripe.createToken({ name: userName }).then(data => {
                if (!!data.error) {
                    this.setState({ isUpdateCard: false })
                } else {
                    this.updateCard(data.token ? data.token.id : "")

                }
            }).catch(err => {
                this.setState({ isUpdateCard: false })
            })
        }
    }
    updateCard = (token: string) => {
        const { onDidUpdateCard } = this.props
        UserServices.updateCard(token).then(() => {
            this.setState({
                isUpdateCard: false
            })
            onDidUpdateCard && onDidUpdateCard()
        }).catch(err => {
            this.setState({ isUpdateCard: false })
        })
    }
    render() {
        const { show, onCancel } = this.props
        const { isUpdateCard } = this.state
        return (

            <div className={classNames("stripe_card")} style={{ top: show ? 0 : "100vh" }}>
                <div className="header">
                    <div onClick={onCancel} style={{ color: COLOR.DEFAULT }}>Cancel</div>
                    <div style={{ fontSize: " 1.2em", fontWeight: 700 }}>Add a Card</div>
                    <div onClick={this.submit}>
                        {isUpdateCard
                            ? <Loader
                                type="Puff"
                                color={COLOR.DEFAULT}
                                height="30"
                                width="30"
                            />
                            : "Done"}
                    </div>
                </div>
                <div className="flexCenter" style={{ height: "40vh" }}>
                    <i className="fa fa-credit-card fa-4x" />
                </div>

                <CardElement
                    className="stripe_card_info"
                    hidePostalCode
                />
            </div>

        )
    }
}

const CardInfo = injectStripe(StripeCardInfo)

export default function StripeCard(props: Props) {
    return (
        <StripeProvider
            apiKey={STRIPE_PK_KEY}
        >
            <Elements>
                <CardInfo
                    {...props}
                />
            </Elements>
        </StripeProvider>
    )
}