import { WagerRedux } from '../types'
import { ApiFetchStatus, WagerServices, WagerServicesIF } from '../../services'
import { WagerActionTypes } from '../actionTypes'
import * as AppRedux from '../store.types'


const actions: WagerRedux.WagerAction = {
    setGetWagerList: (getWagerListStatus: string, wagers?: Array<WagerServicesIF.WagerData>): AppRedux.AppReduxAction<WagerRedux.SetGetWagerList> => {
        return {
            type: WagerActionTypes.setGetWagerList,
            payload: Object.assign({ getWagerListStatus }, wagers && { wagers })
        }
    },
    getWagerList: getWagers,
    setGetInvitedWagerList: (getInvitedWagerListStatus: string, invitedWagers?: Array<WagerServicesIF.WagerData>): AppRedux.AppReduxAction<WagerRedux.SetGetInvitedWagerList> => {
        return {
            type: WagerActionTypes.setGetInvitedWagerList,
            payload: Object.assign({ getInvitedWagerListStatus }, invitedWagers && { invitedWagers })
        }
    },
    getInvitedWagerList: getInvitedWagers,
    setCreateWager: (createWagerStatus: string): AppRedux.AppReduxAction<WagerRedux.SetCreateWager> => {
        return {
            type: WagerActionTypes.setCreateWager,
            payload: {
                createWagerStatus
            }
        }
    },
    createWager,
    setGetHistoryWagerList: (
        getWagerHistoryStatus: string,
        historyWagers?: Array<WagerServicesIF.WagerData>,
        lastPageHistoryWager?: number,
        totalWagerHistory?: number

    ): AppRedux.AppReduxAction<WagerRedux.SetGetHistoryWagerList> => {
        return {
            type: WagerActionTypes.setGetHistoryWagers,
            payload: Object.assign({ getWagerHistoryStatus }, historyWagers && { historyWagers, lastPageHistoryWager, totalWagerHistory })
        }
    },
    getHistoryWagerList: getHistoryWager,
}
function getWagers(callback?: (success: boolean) => void): WagerRedux.WagerActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetWagerList(ApiFetchStatus.fetching))
        WagerServices.getWagerLists().then(res => {
            dispatch(actions.setGetWagerList(ApiFetchStatus.success, res.wagers))
            callback && callback(true)
        }).catch(err => {
            dispatch(actions.setGetWagerList(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}

function getInvitedWagers(callback?: (success: boolean) => void): WagerRedux.WagerActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetInvitedWagerList(ApiFetchStatus.fetching))

        WagerServices.getInvitedWagerLists().then(res => {
            dispatch(actions.setGetInvitedWagerList(ApiFetchStatus.success, res.wagers))
            callback && callback(true)
        }).catch(err => {
            dispatch(actions.setGetInvitedWagerList(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}

function getHistoryWager(pageIndex = 1, callback?: (success: boolean) => void): WagerRedux.WagerActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setGetHistoryWagerList(ApiFetchStatus.fetching))
        WagerServices.getHistoryWagerLists(pageIndex).then(res => {
            dispatch(actions.setGetHistoryWagerList(ApiFetchStatus.success, res.wagers, res.lastPage, res.total))
            callback && callback(true)
        }).catch(err => {
            dispatch(actions.setGetHistoryWagerList(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}

function createWager(body: WagerServicesIF.CreateWagerForm, callback?: (success: boolean, id?: number) => void): WagerRedux.WagerActionResult<void> {
    return (dispatch) => {
        dispatch(actions.setCreateWager(ApiFetchStatus.fetching))
        WagerServices.createWager(body).then(res => {
            dispatch(actions.setCreateWager(ApiFetchStatus.success))
            callback && callback(true, res.wagers.id)
        }).catch(err => {
            dispatch(actions.setCreateWager(ApiFetchStatus.error))
            callback && callback(false)
        })
    }
}
export default actions