import { isNumeric, isEmpty } from 'validator'
export const WagerInfoValidators = {
    name: (errorText?: string) => (value: string) => {
        if (!isEmpty(value)) {
            return ""
        } else {
            return errorText || "Wager name can not be empty!"
        }
    },
    result: (errorText?: string) => (value: string) => {
        if (!isEmpty(value)) {
            return ""
        } else {
            return errorText || "Your result can not be empty!"
        }
    },
    coin: (max: number) => (value: string) => {
        if (isNumeric(value) && Number(value) <= max && Number(value) > 0) {
            return ""
        } else {
            return `The coins must be a valid number and less than ${max}!`
        }
    },
    number: (max: number) => (value: string) => {
        if (isNumeric(value) && Number(value) <= max) {
            return ""
        } else {
            return `The Field must be a valid number!`
        }
    },
}