import React from 'react'
import { InputFieldProps, InputFieldState } from './inputField.types';

class InputFieldSelector extends React.PureComponent<InputFieldProps, InputFieldState> {
    static defaultProps: InputFieldProps = {
        defaultValue: "",
        label: "",
        placeholder: "",

        inputProps: {},

        validator: () => "",
        onChangeText: () => { },
    }

    constructor(props: InputFieldProps) {
        super(props);
        this.state = {
            value: props.defaultValue || "",
            errorText: "",
            showPassword: false
        }
    }

    isError = () => this.props.validator && !!this.props.validator(this.state.value)

    getValue = () => this.state.value

    setValue = (value: string) => this.setState({ value })

}

export default InputFieldSelector