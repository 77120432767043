import { isEmail, isMobilePhone, isNumeric } from 'validator'
export const UserInfoValidators = {
    email: (errorText?: string) => (value: string) => {
        if (isEmail(value)) {
            return ""
        } else {
            return errorText || ""
        }
    },
    password: (errorText?: string) => (value: string) => {
        if (value.length > 5) {
            return ""
        } else {
            return errorText || "Password must be more than 6 characters!"
        }
    },
    rePassword: (password: string) => (value: string) => {
        if (value === password) {
            return ""
        } else {
            return "Re-Password is not match!"
        }
    },
    phone: (errorText?: string) => (value: string) => {
        if (!value || isMobilePhone(value, "any")) {
            return ""
        } else {
            return errorText || "The phone number is not valid!"
        }
    },
    name: (errorText?: string) => (value: string) => {
        return ""
    },
    normalString: (errorText?: string) => (value: string) => {
        return ""
    },
}
export const UserBankInfoValidators = {
    holderName: (errorText?: string) => (value: string) => {
        if (value.trim()) {
            return ""
        } else {
            return errorText || "The holder name is required!"
        }
    },
    bsb: (errorText?: string) => (value: string) => {
        if (isNumeric(value)) {
            return ""
        } else {
            return errorText || "BSB Number must be numeric!"
        }
    },
    accountNumber: (errorText?: string) => (value: string) => {
        if (isNumeric(value)) {
            return ""
        } else {
            return errorText || "The account number must be numeric~"
        }
    }
}