import React from 'react'

import { SideMenuState, SideMenuProps, BaseMenuItemProps } from './sideMenu.type'
import { RouteNames } from '../../../router/router.all';
import { Api, AppNotification } from '../../../instances';

const mql = window.matchMedia(`(min-width: 800px)`);

const getMenuItem = (props: SideMenuProps): Array<BaseMenuItemProps> => {
    const { wagerCount, notificationCount, historyWagerCount } = props
    const items: Array<BaseMenuItemProps> = [
        {
            name: "Dashboard",
            badge: 0,
            event: RouteNames.Authenticated.Home
        },
        {
            name: "Wager",
            badge: wagerCount || 0,
            event: RouteNames.Authenticated.Wager.WagerList(0)
        },
        {
            name: "Profile",
            badge: 0,
            event: RouteNames.Authenticated.User.Profile
        },
        {
            name: "Coin",
            badge: 0,
            event: RouteNames.Authenticated.User.Coin(1)
        },
        {
            name: "Friends",
            badge: 0,
            event: RouteNames.Authenticated.User.Friends
        },
        {
            name: "Friend Invitations",
            badge: 0,
            event: RouteNames.Authenticated.User.FriendInvitation
        },
        {
            name: "Wager History",
            badge: historyWagerCount || 0,
            event: RouteNames.Authenticated.Wager.WagerHistory(1)
        },
        {
            name: "Notification",
            badge: notificationCount || 0,
            event: RouteNames.Authenticated.User.Notification(1)
        },
        {
            name: "Logout",
            badge: 0,
            event: RouteNames.Authenticate.Login
        }
    ]
    return items
}

class SideMenuSelector extends React.PureComponent<SideMenuProps, SideMenuState> {


    static getDerivedStateFromProps(props: SideMenuProps, state: SideMenuState) {

        return {
            ...state,
            items: getMenuItem(props)
        }
    }

    constructor(props: SideMenuProps) {
        super(props);
        this.state = {
            sidebarOpen: false,
            sidebarDocked: mql.matches,
            items: []
        }
    }

    componentDidMount() {
        mql.addListener(this.mediaQueryChanged);
        if (Api.authorizationStatus === "AUTH") {
            this.props.fetchData()
            AppNotification.configureNotification()

        }
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    mediaQueryChanged = () => {
        this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
    }

}



export default SideMenuSelector