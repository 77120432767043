import { UTransaction } from "./../../entities/profile.entity";
import * as UserServicesIF from "./user.types";
import { Api, AppApi, API_USER_URLS } from "../../instances";

import { UserProfile } from "../../entities";
import { parse } from "date-fns";
import { store, UserActions } from "../../store/store.all";

const DefaultRequestConfig: AppApi.RequestConfigProperties = {
  showMessage: false,
  showMessageError: true
};
const DefaultPostRequestConfig: AppApi.RequestConfigProperties = {
  showMessage: true,
  showMessageError: true
};

function getLocalDate(dateStr: string) {
  const date = parse(dateStr);
  return new Date(
    date.valueOf() - new Date().getTimezoneOffset() * 60000
  ).toString();
}
const UserServices = {
  getUserProfile: (
    config = DefaultRequestConfig
  ): Promise<UserServicesIF.GetUserProfileData> => {
    return Api.get<UserProfile>(API_USER_URLS.API_GET_PROFILE, config).then(
      data => {
        return Promise.resolve({
          profile: data
        });
      }
    );
  },
  getTransaction: (
    pageIndex: number,
    config = DefaultRequestConfig
  ): Promise<UserServicesIF.GetTransactionData> => {
    return Api.get<UserServicesIF.GetTransactionResponse>(
      API_USER_URLS.API_LIST_TRANSACTION(pageIndex),
      config
    ).then(data => {
      const list: Array<UTransaction> = data.data.map(x => {
        return {
          ...x,
          created_at: getLocalDate(x.created_at)
        };
      });

      return Promise.resolve({
        data: list,
        lastPage: data.last_page
      });
    });
  },
  getNotifications: (
    pageIndex: number,
    config = DefaultRequestConfig
  ): Promise<UserServicesIF.GetNotificationData> => {
    return Api.get<UserServicesIF.GetNotificationResponse>(
      API_USER_URLS.API_LIST_NOTIFICATION(pageIndex),
      config
    ).then(data => {
      // const list = data.data.map(x => {
      //   return {
      //     ...x,
      //     created_at: getLocalDate(x.created_at)
      //   };
      // });

      return Promise.resolve({
        data: data.data,
        lastPage: data.last_page
      });
    });
  },
  readNotificaion: (
    id: number,
    config = DefaultRequestConfig
  ): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_READ_NOTIFICATION, { id }, config);
  },
  updateUserProfile: (
    body: UserServicesIF.UpdateProfileFormData,
    config = DefaultPostRequestConfig
  ): Promise<UserServicesIF.GetUserProfileData> => {
    console.log("-----body", body);

    let formData = new FormData();
    formData.append("name", body.firstName + " " + body.lastName);
    formData.append("gender", body.gender.toString());
    formData.append("firstName", body.firstName);
    formData.append("lastName", body.lastName);
    formData.append("email", body.email);
    formData.append("phone", body.phone);
    formData.append("country", body.country);
    formData.append("dateOfBirth", body.dateOfBirth);
    formData.append("city", body.city);
    formData.append("state", body.state);
    formData.append("postalCode", body.postalCode);
    formData.append("address", body.address);

    body.avatar && formData.append("avatar", body.avatar);

    return Api.postForm<UserServicesIF.UpdateUserResponse>(
      API_USER_URLS.API_MEMBER_UPDATE_PROFILE,
      formData,
      config
    ).then(res => {
      return Promise.resolve({
        profile: { ...res.profile, coins: { num_of_coin: 0 } }
      });
    });
  },
  updateCard: (
    stripeToken: string,
    config = DefaultPostRequestConfig
  ): Promise<any> => {
    return Api.post<any>(
      API_USER_URLS.API_MEMBER_ADD_CART,
      { stripeToken },
      config
    );
  },
  updateBank: (
    body: UserServicesIF.UpdateBankFormData,
    config = DefaultPostRequestConfig
  ): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_MEMBER_ADD_BANK, body, config);
  },
  updateDeviceToken: (
    body: UserServicesIF.UpdateDeviceTokenForm,
    config = DefaultRequestConfig
  ): Promise<UserServicesIF.NotificationSetting> => {
    return Api.post<UserServicesIF.UpdateDeviceTokenResponse>(
      API_USER_URLS.API_MEMBER_UPDATE_DEVICES,
      body,
      config).then(data => {
        let ret: UserServicesIF.NotificationSetting;
        if (data.device.setting) {
          ret = JSON.parse(data.device.setting)
        } else {
          ret = {
            BANTER: true,
            WAGER: true,
            INVITE: true
          }

        }
        store.dispatch(UserActions.setNotificationSetting(ret))
        return Promise.resolve(ret)
      });
  },
  getUserChatToken: (
    identity: number,
    config = DefaultRequestConfig
  ): Promise<{ token: string }> => {
    return Api.post<{ token: string }>(
      API_USER_URLS.API_MEMBER_GET_TWILIO_TOKEN,
      { identity, platform: "web" },
      config
    ).then(data => {
      return Promise.resolve({
        token: data.token
      });
    });
  },
  buyCoin: (coins: number, config = DefaultPostRequestConfig): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_BUY_COIN, { coins }, config);
  },
  changePass: (
    body: UserServicesIF.ChangePassForm,
    config = DefaultPostRequestConfig
  ): Promise<any> => {
    return Api.post<any>(
      API_USER_URLS.API_CHANGE_PASSSWORD_WITH_AUTHENTICATED,
      body,
      config
    );
  },
  withDrawn: (
    amount: number,
    config = DefaultPostRequestConfig
  ): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_WITH_DRAWN, { amount }, config);
  },
  fetchStatisticData: (
    config = DefaultRequestConfig
  ): Promise<UserServicesIF.GetStatisticDataRepsonse> => {
    return Api.get<UserServicesIF.GetStatisticDataRepsonse>(
      API_USER_URLS.API_MEMBER_GET_SYSTHESIS,
      config
    ).then(data => {
      return Promise.resolve({
        won: data.won,
        lose: data.lose,
        refused: data.refused,
        commission_percent: data.commission_percent,
        unReadNotification: data.unReadNotification
      });
    });
  },
  getDBConfig: (): Promise<any> => {
    return Api.get<any>(API_USER_URLS.API_GET_DB_CONFIG)
  },
  listFriend: (): Promise<Array<UserProfile>> => {
    return Api.get<UserServicesIF.ListFriendResponse>(API_USER_URLS.API_LIST_FRIEND, { showMessage: false, showMessageError: true })
      .then(res => {
        return Promise.resolve(res.items)
      })
  },
  searchMember: (search: string): Promise<Array<UserServicesIF.MemberInfomationToInvite>> => {
    const form = new FormData()
    form.append("search", search)
    return Api.postForm<UserServicesIF.SearchMemberResponse>(API_USER_URLS.API_SEARCH_MEMBER, form, { showMessage: false, showMessageError: true })
      .then(res => {
        return Promise.resolve(res.result)
      })
  },
  listPendingFriend: (): Promise<Array<UserServicesIF.PendingMember>> => {

    return Api.get<UserServicesIF.ListPendingResponse>(API_USER_URLS.API_LIST_PENDING_FRIEND, { showMessage: false, showMessageError: true })
      .then(res => {
        return Promise.resolve(res.items)
      })
  },

  addFriend: (pending_friend_id: number): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_ADD_FRIEND, { pending_friend_id }, DefaultPostRequestConfig)
  },
  acceptFriend: (pending_id: number): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_ACCEPT_FRIEND, { pending_id }, DefaultPostRequestConfig)
  },
  refuseFriend: (pending_id: number): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_REFUSE_FRIEND, { pending_id }, DefaultPostRequestConfig)
  },
  unFriend: (friend_id: number): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_UN_FRIEND, { friend_id }, DefaultPostRequestConfig)
  },
  inviteToApp: (search: string): Promise<any> => {
    return Api.post<any>(API_USER_URLS.API_INVITE_FRIEND_TO_APP, { search }, DefaultPostRequestConfig)
  },
};
export default UserServices;
