
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import { APP_VERSION } from './constances';
import { Api, API_USER_URLS } from './instances';

let regis: ServiceWorkerRegistration

const onReload = () => {
    if (regis) {
        regis.unregister()
    }
}

ReactDOM.render(<App onReload={onReload} />, document.getElementById('root'));
Api.get(API_USER_URLS.API_VERSION)
    .then(response => {
        console.log(response)
    })
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (reg) => {
        Api.get(API_USER_URLS.API_VERSION)
            .then((response: any) => {
                const { result } = response
                if (result !== APP_VERSION) {
                    regis = reg
                    const notification = document.getElementById('update_noti');
                    notification!.className = 'update_show';
                }
            })

    }
});
