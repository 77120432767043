import { Reducer } from 'redux'
import { WagerRedux } from '../types'
import { WagerActionTypes } from '../actionTypes'

import { AppReduxAction } from '../store.types'

import { ApiFetchStatus } from '../../services'

const INITIAL_STATE: WagerRedux.State = {
    getWagerListStatus: ApiFetchStatus.init,
    wagers: [],
    getWagerHistoryStatus: ApiFetchStatus.init,
    historyWagers: [],
    totalWagerHistory: 0,
    getInvitedWagerListStatus: ApiFetchStatus.init,
    invitedWagers: [],
    lastPageHistoryWager: 0,
    createWagerStatus: ApiFetchStatus.init,
}
const reducer: Reducer<WagerRedux.State, AppReduxAction<any>> = (state = INITIAL_STATE, action: AppReduxAction<any>) => {
    const { type, payload } = action
    switch (type) {
        case WagerActionTypes.setGetWagerList:
            return Object.assign({}, state, payload)
        case WagerActionTypes.setGetInvitedWagerList:
            return Object.assign({}, state, payload)
        case WagerActionTypes.setCreateWager:
            return Object.assign({}, state, payload)
        case WagerActionTypes.setGetHistoryWagers:
            return Object.assign({}, state, payload)
    }
    return state
}
export default reducer