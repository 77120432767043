import React from 'react'
import { AvataUploadProps, AvataUploadState } from './avtUpload.types';

class AvataUploadSelector extends React.PureComponent<AvataUploadProps, AvataUploadState> {
    static defaultProps: AvataUploadProps = {
        defaultSource: ""
    }

    constructor(props: AvataUploadProps) {
        super(props);
        this.state = {
            file: undefined,
            preview: props.defaultSource || ""
        }
    }

    getFile = () => this.state.file

}

export default AvataUploadSelector