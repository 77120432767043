import React, { CSSProperties } from 'react'

import { LazyImage } from "react-lazy-images";

import { APP_IMGS } from '../../../assets'
import './Avatar.stylesheet.sass'

export type AvatarProps = {
    source: string,
    containerStyle?: CSSProperties,
    size?: number
}

export function Avatar(props: AvatarProps) {
    const { source, containerStyle, size = 80 } = props

    return (
        <div style={containerStyle}>
            <LazyImage
                src={source}
                alt="user-avartar"
                placeholder={({ ref }) => (
                    <img
                        style={{ width: size, height: size, borderRadius: size * 0.5 }}
                        ref={ref}
                        src={APP_IMGS.USER_AVT}
                        alt={"user-df-avatar"}
                    />
                )}
                actual={() => <img
                    style={{ width: size, height: size, borderRadius: size * 0.5, objectFit: "cover" }}
                    src={source}
                    alt="avatar" />
                }
                error={() => (
                    <img
                        style={{ width: size, height: size, borderRadius: size * 0.5 }}
                        // ref={ref}
                        src={APP_IMGS.USER_AVT}
                        alt={"user-df-avatar"}
                    />
                )}
            />
        </div>

    )
}