import React from 'react'
import './date-dropdown.min.sass'
import classNames from 'classnames'
//@ts-ignore
import DropdownDate from 'react-dropdown-date';
import { format } from 'date-fns'

import { DateDropdownPickerProps, DateDropdownPickerState } from './date-dropdown.types'
import { COLOR } from '../../res';

class DateDropdownPicker extends React.PureComponent<DateDropdownPickerProps, DateDropdownPickerState> {

    static defaultProps: DateDropdownPickerProps = {
        label: "",
        defaultValue: new Date(),
        minDate: new Date(),
        maxDate: new Date()
    }
    regex: string;
    canClosePicker = false
    constructor(props: DateDropdownPickerProps) {
        super(props);
        this.regex = "YYYY/MM/DD"
        this.state = {
            date: props.defaultValue!
        }
    }

    getValue = () => {
        console.log(this.state.date)
        return this.state.date
    }

    onChange = (date: Date) => {
        const { onChange } = this.props
        onChange && onChange(date)
        console.log(date)
        this.setState({ date })
    }

    render() {
        const { label, minDate, maxDate, noDefaut } = this.props
        const { date } = this.state
        return (
            <div
                className={classNames("dt_dropdown_container")}
            >
                <div className="dt_dropdown_label">{label}</div>
                <DropdownDate
                    startDate={format(minDate!, this.regex)}
                    endDate={format(maxDate!, this.regex)}
                    date={format(date, this.regex)}
                    onDateChange={this.onChange}
                    ids={                               // optional
                        {
                            year: 'select-year',
                            month: 'select-month',
                            day: 'select-day'
                        }
                    }
                    names={                             // optional
                        {
                            year: 'year',
                            month: 'month',
                            day: 'day'
                        }
                    }
                    classes={                           // optional
                        {
                            dateContainer: 'dropdown_container',
                            yearContainer: 'year_container',
                            monthContainer: 'month_container',
                            dayContainer: 'day_container',
                            year: 'classes classes',
                            month: 'classes classes',
                            day: 'classes classes',
                            yearOptions: 'classes',
                            monthOptions: 'classes',
                            dayOptions: 'classes'
                        }
                    }
                    defaultValues={                     // optional
                        {
                            year: noDefaut ? "Year" : format(date, "YYYY"),
                            month: noDefaut ? "Month" : format(date, "MMM").toLocaleUpperCase(),
                            day: noDefaut ? "Day" : format(date, "DD"),
                        }
                    }
                    options={                           // optional
                        {
                            yearReverse: true,              // false by default
                            monthShort: true,               // false by default
                            monthCaps: true                 // false by default
                        }
                    }
                />
                <div style={{ width: 60, height: 45, }} className="flexCenter">
                    <i className="fa fa-calendar" style={{ color: COLOR.SUCCESS, fontSize: "1.5em" }} />
                </div>
            </div>
        )
    }
}

export default DateDropdownPicker